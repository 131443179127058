import { Dropdown, DropdownButton } from 'react-bootstrap';
import { ComponentProps } from '../layout-interfaces';
import { localized } from '../component-hooks';
import { useCallback, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from './icon-component';
import { FormikContext } from 'formik';

export const DropdownComponent = (props: ComponentProps) => {
  const formikContext = useContext(FormikContext);
  const {
    name,
    context,
    variables,
    props: { options = {}, onClick, label, icon, items } = {},
  } = props;

  // Wrap the onClick handler with useCallback to prevent unnecessary re-renders
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      onClick?.(variables);
    },
    [onClick, variables],
  );

  // Create a memoized callback for item clicks to prevent re-creating the function on every render
  const handleItemClick = useCallback(
    (item: any) => () => {
      item?.onClick &&
        context?.action?.(
          item.onClick,
          { ...variables, ...formikContext?.values },
          {
            sender: name,
            actions: props.actions,
            formikContext,
          },
        );
    },
    [context, items, variables],
  );

  return (
    <DropdownButton
      onClick={handleClick}
      data-testid={name}
      variant={options.variant ?? 'secondary'}
      title={
        <>
          {icon && (
            <>
              <Icon icon={icon} /> &nbsp;
            </>
          )}
          {localized(label) ?? name}
        </>
      }
      {...options}
    >
      {typeof items?.map === 'function' &&
        items?.map(
          (item, index) =>
            item && (
              <Dropdown.Item
                key={item.value ?? index}
                data-testid={item.value}
                eventKey={item.value}
                // Use the memoized callback and pass the item
                onClick={handleItemClick(item)}
              >
                {localized(item.label) ?? item.value}
              </Dropdown.Item>
            ),
        )}
    </DropdownButton>
  );
};
