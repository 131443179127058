import { $enum } from 'ts-enum-util';
import { ReactSelectItem } from '../models/custom.models';
import {
  AccountingItemDto,
  ApplyBy,
  AttachmentDto,
  CommodityDto,
  CommodityStatusStage,
  ContactDto,
  CustomFieldDto,
  CustomFieldType,
  FileType,
  OrderDocumentDto,
  PackingStatuses,
  PaidAs,
  PickingActivityStatuses,
  PutAwayActivityStatuses,
  S3UriTypes,
  StatusStage,
} from '../models/data.models';
import { getContact } from '../modules/contacts/contacts.store';
import { addMessage, messagesStore } from '../modules/common/messages.store';
import { v4 } from 'uuid';
import { KeyboardEvent } from 'react';
import * as Yup from 'yup';
import { getAttachmentContentFx } from '../modules/attachments/attachments.store';
import { getOrderDocumentContentUrlFx } from '../modules/orderDocuments/orderDocuments.store';

export const getEnumKeyByValue = (
  input: any | any[],
  enumName: object,
): any | any[] => {
  if (Array.isArray(input)) {
    return $enum(enumName)
      .map((value, key) => {
        return {
          key: key,
          value: value,
        };
      })
      .filter((item) => input.includes(item.value))
      .map((item) => item.key);
  } else {
    return $enum(enumName)
      .map((value, key) => {
        return {
          key: key,
          value: value,
        };
      })
      .filter((item) => input === item.value)[0]?.key;
  }
};

export const getEnumValueByValue = (
  input: any | any[],
  enumName: object,
): any | any[] => {
  if (Array.isArray(input)) {
    return $enum(enumName)
      .map((value, key) => {
        return {
          key: key,
          value: value,
        };
      })
      .filter((item) => input.includes(item.value))
      .map((item) => item.key);
  } else {
    const rt = $enum(enumName)
      .map((value, key) => {
        return {
          key: key,
          value: value,
        };
      })
      .filter((item) => input === item.key)[0];
    return rt?.value;
  }
};

export const countDecimals = (number: Number) => {
  if (Math.floor(number.valueOf()) === number.valueOf()) return 0;

  const str = number.toString();
  if (str.indexOf('.') !== -1) {
    return str.split('.')[1].length || 0;
  }
  return 0;
};

export const getEnumValues = (enumObject: any): ReactSelectItem[] => {
  return $enum(enumObject).map((value, key) => {
    return {
      value: key,
      label: value,
    };
  });
};

export const getPaidAs = async (contactId: number): Promise<PaidAs> => {
  return getContact({
    contactId: Number(contactId) || null,
  }).then((contactData: ContactDto) => {
    return contactData.paidAs;
  });
};

export const createErrorMessage = (inputName: string, errorMessage: string) => {
  const message = `${inputName}: ${errorMessage}`;
  const isThereAlreadySuchError = messagesStore
    .getState()
    .messages.some((messageObject) => messageObject.message === message);
  if (!isThereAlreadySuchError)
    addMessage({ id: v4(), message, type: 'danger' });
};

export const findInputName = (
  formId: string,
  labelFor: string,
  tabName?: string,
): string => {
  let formTabName = '';
  if (tabName) {
    formTabName = `${
      (document.querySelector(`li[name="${tabName}"]`) as HTMLElement)
        ?.innerText
    } - `;
  }
  const inputName = (document.querySelector(
    `#${formId} label[for="${labelFor}"] .input-label-primary`,
  ) as HTMLElement)?.innerText;
  return `${formTabName}${inputName}`;
};

export const validateNumberInput = (e: KeyboardEvent) => {
  if (
    (e.key < '0' || e.key > '9') &&
    e.key !== '-' &&
    e.key !== '.' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight' &&
    e.key !== 'Delete' &&
    e.key !== 'Backspace' &&
    e.key !== 'Shift' &&
    e.key !== 'Control' &&
    e.key !== 'Tab' &&
    e.key !== 'End' &&
    e.key !== 'Home' &&
    ((e.key !== 'a' &&
      e.key !== 'v' &&
      e.key !== 'c' &&
      e.key !== 'x' &&
      e.key !== 'z') ||
      !e.getModifierState('Control')) &&
    ((e.key !== 'Home' &&
      e.key !== 'End' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight') ||
      !e.getModifierState('Shift'))
  ) {
    e.preventDefault();
  }
};

export const validatePositiveNumberInput = (e: KeyboardEvent) => {
  if (
    (e.key < '0' || e.key > '9') &&
    e.key !== '.' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight' &&
    e.key !== 'Delete' &&
    e.key !== 'Backspace' &&
    e.key !== 'Shift' &&
    e.key !== 'Control' &&
    e.key !== 'Tab' &&
    e.key !== 'End' &&
    e.key !== 'Home' &&
    ((e.key !== 'a' && e.key !== 'c' && e.key !== 'x' && e.key !== 'z') ||
      !e.getModifierState('Control')) &&
    ((e.key !== 'Home' &&
      e.key !== 'End' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight') ||
      !e.getModifierState('Shift'))
  ) {
    e.preventDefault();
  }
};

export const splitByCapitals = (str?: string): string => {
  return str ? str.split(/(?=[A-Z])/).join(' ') : '';
};

export const escapeString = (str?: string, columnType?: string): string => {
  const luceneEscapes = [
    '\\',
    '+',
    '-',
    '&&',
    '||',
    '!',
    '(',
    ')',
    '{',
    '}',
    '[',
    ']',
    '^',
    '"',
    '~',
    '*',
    '?',
    ':',
    ' ',
  ];
  luceneEscapes.forEach((symbol) => {
    if (symbol === '\\' || symbol === '"') {
      if (columnType === 'customField') {
        str = str.replaceAll(symbol, `\\\\\\${symbol}`);
      } else {
        str = str.replaceAll(symbol, `\\${symbol}`);
      }
    } else if (symbol === '+') {
      str = str.replaceAll(symbol, `\\%2b`);
    } else {
      str = str.replaceAll(symbol, `\\${symbol}`);
    }
  });
  return str;
};

export const unescapeString = (
  str?: string,
  isCustomField?: boolean,
): string => {
  const luceneEscapes = [
    '\\\\\\\\',
    '\\\\\\"',
    '\\\\',
    '\\"',
    '\\ ',
    '\\+',
    '\\-',
    '\\&&',
    '\\||',
    '\\!',
    '\\(',
    '\\)',
    '\\{',
    '\\}',
    '\\[',
    '\\]',
    '\\^',
    '\\~',
    '\\*',
    '\\?',
    '\\:',
  ];
  luceneEscapes.forEach((symbol) => {
    if ((symbol === '\\\\\\\\' || symbol === '\\\\\\"') && isCustomField) {
      str = str.replaceAll(symbol, symbol.slice(3));
    } else if ((symbol === '\\\\' || symbol === '\\"') && !isCustomField) {
      str = str.replaceAll(symbol, symbol.slice(1));
    } else {
      str = str.replaceAll(symbol, symbol.slice(1));
    }
  });
  return str;
};

export const generateValidationSchemaWithCustomFields = (
  customFields: CustomFieldDto[],
  schema: any,
) => {
  customFields?.forEach((customField) => {
    if (customField.customFieldType === CustomFieldType.Number) {
      const customFieldSchema = Yup.object()
        .shape({
          customValues: Yup.object()
            .shape({
              [`${customField.internalName}`]: Yup.string()
                .transform((value) => (value === null ? '' : value))
                .test('numberFormat', 'Incorrect number format', (value) => {
                  return (
                    (new RegExp(
                      /^(0$|-?[1-9]\d*([\.\,]\d*[0-9]$)?|-?0\.\d*[0-9])$/gm,
                    ).test(value?.toString()) &&
                      Number(value) < Number.MAX_SAFE_INTEGER &&
                      Number(value) > Number.MIN_SAFE_INTEGER) ||
                    value === undefined ||
                    value === ''
                  );
                })
                .test('length', 'Max number length is 17', (value) => {
                  return (
                    value === undefined || value?.replace('-', '').length <= 17
                  );
                })
                .nullable(true),
            })
            .nullable(true),
        })
        .nullable(true);
      schema = schema.concat(customFieldSchema);
    }
  });
  return schema;
};

export const parseGeocodeResults = (result: any) => {
  const types = {
    home: ['street_number', 'premise', 'subpremise'],
    postal_code: ['postal_code'],
    street: [
      'street_address',
      'route',
      'point_of_interest',
      'intersection',
      'airport',
      'park',
      'point_of_interest',
      'natural_feature',
      'plus_code',
    ],
    state: ['administrative_area_level_1'],
    region: [
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
      'administrative_area_level_6',
      'administrative_area_level_7',
    ],
    city: ['locality'],
    country: ['country'],
  };

  const address = {
    home: [],
    postal_code: [],
    street: [],
    stateCode: [],
    stateName: [],
    region: [],
    city: [],
    countryCode: [],
    countryName: [],
  };

  result.address_components.forEach((component) => {
    for (const type in types) {
      if (types[type].includes(component.types[0])) {
        if (type === 'country' || type === 'state') {
          address[`${type}Code`].push(component.short_name);
          address[`${type}Name`].push(component.long_name);
        } else {
          address[type].push(component.long_name);
        }
      }
    }
  });

  const addressLine =
    address.home.length > 0 && address.street.length > 0
      ? `${address.home} ${address.street.join(', ')}`
      : `${address.region.join(', ')}`;
  const addressLineEU = address.street.join(', ');
  const houseNumber = address.home.join(' ');
  const city = address.city.join(', ');
  const postalCode = address.postal_code.toString();
  const stateCode = address.stateCode.toString();
  const stateName = address.stateName.toString();
  const countryCode = address.countryCode.toString();
  const countryName = address.countryName.toString();
  const latitude = result.geometry?.location?.lat();
  const longitude = result.geometry?.location?.lng();

  return {
    addressLine,
    addressLineEU,
    houseNumber,
    city,
    postalCode,
    stateCode,
    stateName,
    countryCode,
    countryName,
    latitude,
    longitude,
  };
};

export const getCustomFieldOptionsValues = (
  options: string,
): ReactSelectItem[] => {
  if (options.length > 0)
    return (
      options.split(';')?.map((value) => {
        return {
          value,
          label: value,
        };
      }) ?? []
    );
  else return null;
};

export const countTotalContainerCommodityWeight = (
  commodities: CommodityDto[],
): CommodityDto[] => {
  const data: CommodityDto[] = [...commodities];
  data.forEach((commodity) => {
    if (commodity && commodity.containerCommodities) {
      if (commodity.isRemoved !== true) {
        commodity.weight = commodity.containerCommodities.reduce(
          (sum, item) => sum + item.weight * item.pieces,
          0,
        );
      }
    }
  });
  return data;
};

export const getActivityStatusStage = (activityStatus: string) => {
  switch (activityStatus) {
    case PickingActivityStatuses.Picked:
    case PutAwayActivityStatuses.Completed:
    case CommodityStatusStage.Completed:
    case PackingStatuses.Scanned:
      return StatusStage.Completed;
    case PickingActivityStatuses.Damaged:
    case PickingActivityStatuses.NotFound:
    case PackingStatuses.NotScanned:
      return StatusStage.Inactive;
    case PickingActivityStatuses.AwaitingPicking:
    case PutAwayActivityStatuses.InMovement:
    case CommodityStatusStage.InProgress:
    case CommodityStatusStage.Pending:
      return StatusStage.InProgress;
    default:
      return StatusStage.InProgress;
  }
};

export const countTotalContainerCommodityDeclaredValue = (
  commodities: CommodityDto[],
): number => {
  let declaredAmount: number = 0;

  if (commodities) {
    if (
      commodities.every((commodity) =>
        commodity.containerCommodities?.every(
          (containerCommoditiy) =>
            containerCommoditiy.customValues != null &&
            !!containerCommoditiy.customValues['declaredValue'] &&
            containerCommoditiy.customValues['declaredValue'] !== '0.00',
        ),
      )
    ) {
      commodities.forEach((commodity) => {
        declaredAmount += commodity.containerCommodities?.reduce(
          (sum, containerCommoditiy) =>
            sum + Number(containerCommoditiy.customValues['declaredValue']),
          0,
        );
      });
    } else {
      commodities.forEach((commodity) => {
        commodity.containerCommodities?.forEach((containerCommodity) => {
          containerCommodity.containerCommodities?.forEach(
            (declaredCommodity) => {
              if (
                declaredCommodity.quantity &&
                declaredCommodity.unitaryValue
              ) {
                declaredAmount +=
                  declaredCommodity.quantity * declaredCommodity.unitaryValue;
              }
            },
          );
        });
      });
    }
  }

  return declaredAmount;
};

export const getAttachmentsLinks = (attchments: AttachmentDto[]) => {
  attchments.forEach(async (item) => {
    let url = await getAttachmentContentFx({
      attachment: item,
      uriType: S3UriTypes.FileUri,
    });
    item.fileUri = url;

    url = await getAttachmentContentFx({
      attachment: item,
      uriType: S3UriTypes.PreviewUri,
    });
    item.previewUri = url;

    url = await getAttachmentContentFx({
      attachment: item,
      uriType: S3UriTypes.ThumbnailUri,
    });
    item.thumbnailUri = url;
  });
};

export const getFileType = (fileName: string) => {
  if (fileName && fileName.trimEnd() && fileName.includes('.')) {
    let extension = fileName.split('.')[1];
    extension = `${extension.slice(0, 1).toUpperCase()}${extension
      .slice(1)
      .toLowerCase()}`;
    return FileType[extension];
  }

  return FileType.Default;
};

export const getDocumentsLinks = async (documents: OrderDocumentDto[]) => {
  documents.forEach(async (item) => {
    let url = await getOrderDocumentContentUrlFx({
      orderDocument: item,
      fileType: getFileType(item.orderDocumentName),
    });
    item.lastGeneratedFile = url;
  });
};

export const fixRateDataForSubmit = (data) => {
  if (data?.tariff?.rateData) {
    const sortedRateData = data.tariff.rateData.sort((a, b) => {
      if (a.rateIndex < b.rateIndex) {
        return -1;
      }
      if (a.rateIndex > b.rateIndex) {
        return 1;
      }
      return 0;
    });
    data = {
      ...data,
      tariff: { ...data.tariff, rateData: sortedRateData },
    };
  }

  if (data?.tariff?.rateData && data?.tariff?.applyBy === ApplyBy.Container) {
    const filteredRateData = data.tariff.rateData.filter(
      (rate) => rate.rateIndex !== 0 && rate.rateIndex !== undefined,
    );
    data = {
      ...data,
      tariff: { ...data.tariff, rateData: filteredRateData },
    };
  }
};

export const destructurizeGQLCustomValues = (customValues: any) => {
  try {
    let newCustomValues = {};
    customValues.forEach((x) => {
      newCustomValues = { ...newCustomValues, ...{ [x.key]: x.value } };
    });
    return newCustomValues;
  } catch {
    return customValues;
  }
};

export const isTrue = (value?: string | boolean | null) => {
  return value === true || value?.toString()?.toLowerCase() === 'true';
};

export const getDateRange = (value: Date[]): string[] => {
  const startOfTheRange = new Date(value[0].setHours(0, 0, 0, 0)).toISOString();
  const endOfTheRange = new Date(
    value[1].setHours(23, 59, 59, 999),
  ).toISOString();
  return [startOfTheRange, endOfTheRange];
};
