// Field Component
import { Field, useField, useFormikContext } from 'formik';
import { ComponentProps } from '../layout-interfaces';
import { ReactSelect } from './select-component';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactSelectAsync } from './select-async-component';
import { hasPermission, localized, parseTemplate } from '../component-hooks';
import { ReactDatepickerComponent } from '../../react-datepicker/react-datepicker.component';
import { get } from 'lodash';
import Select from 'react-select';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { JsonField, YamlField } from './code-component';
import { AutocompleteComponent } from './autocomplete.component';
import { AutocompleteComponentAsync } from './autocomplete-async.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { AutocompleteComponentGooglePlaces } from './autocomplete-googleplaces.component';
import { NumberInputComponent } from './number-input-component';
import { RangeDatePicker } from '../../rangeDatePicker/rangeDatePicker';
import { QuillInput } from '../../input/input-quill.component';
import { PasswordInputComponent } from './password-input-component';
import { NumberSelectInputComponent } from './number-select-input-component';
import { AttachmentField } from './attachment-component';

export const FieldComponent = (props: ComponentProps) => {
  const formikContext = useFormikContext<any>();
  // name is required for field
  if (!props.name) {
    return (
      <div className="alert alert-danger" role="alert">
        Field Name is required.
      </div>
    );
  }

  const [item, setItem] = useState(null);

  const {
    type,
    label,
    className,
    id,
    disabled,
    readonly,
    prefix,
    placeholder,
    value,
    rows,
    autoComplete,
    transformValue,
    defaultCountry,
    onEditClick,
    isClearable,
    ...otherProps
  } = props?.props || {};

  const parsedDisabled = parseTemplate(disabled, {
    ...props?.props,
    ...props?.variables,
    ...props?.context?.store,
  });

  const parsedPrefix = parseTemplate(prefix, { ...props?.variables });
  const parsedName = parseTemplate(props.name, { ...props?.variables });
  const parsedPlaceholder = parseTemplate(placeholder, props?.context?.store);
  // Generate a unique ID if not provided
  const fieldId = (parsedPrefix ?? '') + (id || parsedName);
  const fieldName = (parsedPrefix ?? '') + parsedName;

  const onClick = useCallback(
    (e) => {
      // Prevent event from bubbling up the DOM tree
      e.stopPropagation();

      if (props?.props?.onClick && props.context?.action) {
        props.context?.action(
          props.props.onClick,
          {
            ...props.variables,
          },
          {
            sender: props.name,
            actions: props.actions,
            formikContext,
          },
        );
      }
    },
    [props],
  );

  const onChange = useCallback(
    (e: any) => {
      const mainFormikContext = props?.props?.formikContext ?? formikContext;
      let value = e?.target?.value ?? e?.value ?? e;
      if (transformValue?.onEdit) {
        value = parseTemplate(transformValue.onEdit, {
          ...props?.context?.store,
          ...mainFormikContext.values,
          value,
        });
      }

      formikContext.setFieldValue(
        fieldName,
        type === 'checkbox' ? e.target.checked : value,
      );

      if (props?.props?.onChange && props.context?.action) {
        props.context?.action(
          props.props.onChange,
          {
            ...props.variables,
            changedValues: value,
            checked: e?.target?.checked,
          },
          {
            sender: props.name,
            actions: props.actions,
            formikContext: mainFormikContext,
          },
        );
      }
    },
    [props],
  );

  const onEditIconClick = useCallback(() => {
    if (onEditClick && props.context?.action) {
      const formFieldPrefix =
        props?.props?.prefix?.toString()?.replace(/\.$/, '') ?? null;

      const formValues = formFieldPrefix
        ? {
            ...get(formikContext.values, formFieldPrefix),
          }
        : { ...formikContext.values };

      if (props.props?.options?.valueFieldName) {
        formValues[props.props.options.valueFieldName] = field.value;
      }

      props.context?.action(
        onEditClick,
        {
          ...props?.variables,
          ...props?.context?.store,
          ...formValues,
          item,
        },
        {
          sender: props.name,
          actions: props.actions,
          formikContext,
        },
      );
    }
  }, [props, item]);

  const convertFileToByteArray = (eventInput) => {
    const file = eventInput.target.files[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      // @ts-ignore
      const byteArray = [...new Uint8Array(event.target.result)];
      onChange({
        target: {
          value: {
            name: file.name,
            type: file.type,
            size: file.size,
            data: byteArray,
          },
        },
      });
    };

    reader.readAsArrayBuffer(file);
  };

  // Use Formik useField hook for handling field state and validation
  let field: any;
  let meta: any;
  try {
    [field, meta] = useField(fieldName);
    // idk why but useField can't automatically get error for flat chained fieldName, e.g. item1.item2.item3
    meta.error = formikContext.errors[fieldName];
  } catch (e) {
    // If the field is not found, return an error message
    return (
      <div className="alert alert-danger" role="alert">
        {props.name} Field is outside of a Formik context.
      </div>
    );
  }

  useEffect(() => {
    const valueHasBeenTransformed = get(
      formikContext?.values,
      `transformedValues.${fieldName}`,
    );

    if (transformValue?.onLoad && !valueHasBeenTransformed) {
      formikContext.setFieldValue(
        fieldName,
        parseTemplate(transformValue.onLoad, {
          ...props?.context?.store,
          ...formikContext.values,
          ...props?.variables,
          value: field.value,
        }),
      );
      formikContext.setFieldValue(`transformedValues.${fieldName}`, true);
    }
  }, []);

  // Construct class names with validation feedback
  const inputClassNames = useMemo(() => {
    let baseClass = 'form-control';
    if (type === 'select') {
      baseClass = 'form-select';
    }
    if (type === 'checkbox' || type === 'radio') {
      baseClass = '';
    }
    return `${baseClass} ${props?.props?.options?.className} ${
      meta.touched && meta.error ? 'is-invalid' : ''
    }`;
  }, [type, meta.touched, meta.error]);

  let component: any;
  let componentProps: any;
  let options: any;

  switch (type) {
    case 'select':
      component = ReactSelect;
      componentProps = props;
      options =
        parseTemplate(props?.props?.items, {
          ...props?.context?.store,
          ...formikContext.values,
          ...props?.variables,
        }) ?? [];
      break;
    case 'select-async':
      component = ReactSelectAsync;
      componentProps = { ...props, setItem, item };
      break;
    case 'autocomplete':
      component = AutocompleteComponent;
      componentProps = props;
      break;
    case 'autocomplete-async':
      component = AutocompleteComponentAsync;
      componentProps = props;
      break;
    case 'autocomplete-googleplaces':
      component = AutocompleteComponentGooglePlaces;
      componentProps = props;
      break;
    case 'quill':
      component = QuillInput;
      componentProps = props;
      break;
    case 'number':
      component = NumberInputComponent;
      componentProps = props;
      break;
    case 'number-select':
      component = NumberSelectInputComponent;
      componentProps = props;
      break;
    case 'rangedatetime':
      component = RangeDatePicker;
      componentProps = props;
      break;
    case 'attachment':
      component = AttachmentField;
      componentProps = props;
      break;
    case 'password':
      component = PasswordInputComponent;
      componentProps = props;
      break;
    case 'checkbox':
    case 'text':
    case 'textarea':
    case 'datetime':
    case 'time':
    case 'radio':
    case 'file':
    case 'hidden':
    case 'phone':
      // These types will use the default Field component
      break;
    case 'object':
      component = JsonField;
      componentProps = {
        mode: 'json',
        output: 'object',
      };
      break;
    case 'yaml':
      component = YamlField;
      componentProps = {
        mode: 'yaml',
        height: '800px',
      };
      break;
    default:
      return (
        <div className="alert alert-danger" role="alert">
          Field type <b>{type}</b> not supported.
        </div>
      );
  }

  return (
    <div
      className={`mb-1 col-12 px-3 ${
        type === 'checkbox' || type === 'radio'
          ? 'd-flex align-items-center'
          : ''
      }`}
    >
      {label && type !== 'checkbox' && type !== 'radio' && (
        <span className="d-flex justify-content-between align-items-baseline">
          <label htmlFor={fieldId} className="input-label">
            {localized(
              parseTemplate(label, {
                ...props?.context?.store,
                ...formikContext.values,
                ...props?.variables,
              }),
            )}
          </label>
          {type === 'select-async' &&
            field.value &&
            !props?.props?.options?.allowMultiple &&
            onEditClick &&
            hasPermission(
              props?.props?.options?.navigateActionPermission,
              props.variables,
            ) && (
              <div
                className="menu-icon-wrapper d-flex icon-edit-entity m-0 p-0"
                onClick={onEditIconClick}
                data-cy={`${fieldId}-edit-icon`}
              >
                <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" />
              </div>
            )}
        </span>
      )}
      {type === 'checkbox' ? (
        <input
          {...field}
          id={fieldId}
          type="checkbox"
          disabled={parsedDisabled}
          checked={
            field.value === 'true' ||
            field.value === 'True' ||
            field.value === true
          }
          className={inputClassNames}
          onClick={onClick}
          onChange={(e) => onChange(e)}
        />
      ) : type === 'radio' ? (
        <input
          {...field}
          id={fieldId}
          name={fieldName}
          type="radio"
          disabled={parsedDisabled}
          checked={value == get(formikContext?.values, fieldName)}
          value={value}
          className={inputClassNames}
          onClick={onClick}
        />
      ) : type === 'file' ? (
        <input
          {...field}
          id={fieldId}
          name={fieldName}
          type="file"
          disabled={parsedDisabled}
          onChange={convertFileToByteArray}
          value={value}
          className={inputClassNames}
          onClick={onClick}
        />
      ) : type === 'datetime' ? (
        <ReactDatepickerComponent
          {...field}
          id={fieldId}
          placeholder={parsedPlaceholder}
          disabled={parsedDisabled}
          readonly={readonly}
          onChange={(e) => onChange(e)}
          dateFormat={props?.props?.dateFormat ?? 'P'}
        />
      ) : type === 'time' ? (
        <ReactDatepickerComponent
          {...field}
          id={fieldId}
          placeholder={parsedPlaceholder}
          disabled={parsedDisabled}
          showTimeSelect={true}
          showTimeSelectOnly={true}
          timeFormat={props?.props?.timeFormat ?? 'p'}
          dateFormat={props?.props?.timeFormat ?? 'p'}
          timeIntervals={props?.props?.timeInterval ?? 15}
          readonly={readonly}
        />
      ) : type === 'select' ? (
        <Select
          {...field}
          options={options}
          isDisabled={parsedDisabled}
          componentProps={componentProps}
          className={inputClassNames}
          placeholder={parsedPlaceholder}
          onChange={(e) => onChange(e)}
          onClick={onClick}
          classNamePrefix={'select'}
          isClearable={isClearable ?? true}
          isMulti={props?.props?.options?.allowMultiple ?? false}
          isReadOnly={readonly}
          components={{
            IndicatorSeparator: () => null,
          }}
          value={
            props?.props?.options?.allowMultiple
              ? field.value
                ? field.value.map((i) => ({
                    label: options?.find((option) => option.value === i.value)
                      ?.label,
                    value: i.value,
                  }))
                : null
              : field.value
              ? {
                  label: options?.find((option) => option.value === field.value)
                    ?.label,
                  value: field.value,
                }
              : null
          }
          getOptionLabel={(option) => option?.label}
          getOptionValue={(option) => option?.value}
        />
      ) : type === 'phone' ? (
        <PhoneInput
          {...field}
          data-testid={fieldId}
          value={get(formikContext?.values, fieldName)}
          onChange={(e) => onChange(e)}
          className={inputClassNames}
          id={fieldId}
          placeholder={parsedPlaceholder}
          international
          defaultCountry={defaultCountry ?? 'US'}
        />
      ) : (
        <Field
          as={type === 'textarea' ? type : ''}
          {...field}
          rows={type === 'textarea' ? rows ?? 2 : 1}
          options={otherProps.options}
          data-testid={fieldId}
          id={fieldId}
          type={type}
          autoComplete={autoComplete ?? 'off'}
          disabled={parsedDisabled}
          componentProps={componentProps}
          component={component}
          className={inputClassNames}
          placeholder={parsedPlaceholder}
          onChange={(e) => onChange(e)}
          onClick={onClick}
          variables={props.variables}
          readOnly={readonly}
        />
      )}
      {type === 'checkbox' && label && (
        <label
          htmlFor={fieldId}
          className="form-check-label check-radio-label pl-2"
        >
          {localized(parseTemplate(label, { ...props?.variables }))}
        </label>
      )}
      {type === 'radio' && label && (
        <label
          htmlFor={fieldId}
          className="form-check-label check-radio-label pl-2"
        >
          {localized(parseTemplate(label, { ...props?.variables }))}
        </label>
      )}
      {meta.error ? <div className="invalid-feedback">{meta.error}</div> : null}
    </div>
  );
};
