import { useCallback, useEffect, useState } from 'react';
import { ComponentRender } from '../component-render';
import { ComponentProps } from '../layout-interfaces';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { queryGraphQlFx } from '../../../../graphql/graphql.store';
import {
  parseTemplate,
  useComponentQueries,
  useComponentVariables,
} from '../component-hooks';
import { LoadingIndicator } from '../../loading-indicator/loading-indicator.component';
import { validationSchemaToYup } from '../actions/validate';
import { merge } from 'lodash';

const useDataLoaders = (
  props: ComponentProps,
  queries: any,
  variables: any,
) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({} as any);

  useEffect(() => {
    if (queries) {
      props?.props?.dataLoaders?.forEach((dataLoader) => {
        setLoading(true);

        queryGraphQlFx({
          query: queries[dataLoader.query].command,
          variables: {
            ...parseTemplate(queries[dataLoader.query].variables, variables),
            ...parseTemplate(dataLoader.variables, variables),
          },
        })
          .then((res) => {
            setData((prevState) => {
              return {
                ...prevState,
                [dataLoader.name]: res,
              };
            });
          })
          .catch((e) => {
            console.log('error', e);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  }, [queries]);

  return { loading };
};

export const FormComponent = (props: ComponentProps) => {
  const [initialValues, setInitialValues] = useState({} as any);
  const { variables } = useComponentVariables(props, {
    formName: props.name,
    createMode:
      (props.variables &&
        props.variables['*'] &&
        props.variables['*'] === 'create') ||
      false,
  });
  const { getPropertyValue, queries } = useComponentQueries(props, variables);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedInitialValues, setIsLoadedInitialValues] = useState(false);
  const [validationSchema, setValidationSchema] = useState(null);

  useEffect(() => {
    setIsLoadedInitialValues(false);
  }, [props.context?.refreshHandlers?.[props.props.refreshHandler]]);

  useEffect(() => {
    if (props?.props?.initialValues && queries) {
      if (!isLoadedInitialValues) {
        getPropertyValue(props?.props?.initialValues, {})
          .then((data) => {
            if (props.props.initialValues.append) {
              data = {
                ...data,
                ...parseTemplate(props.props.initialValues.append, {
                  ...variables,
                  ...data,
                }),
              };
            }
            setInitialValues(data);
            if (props.context?.setStore)
              props.context?.setStore(props.name, {
                ...data,
              });
          })
          .catch((e) => {
            console.log('initialValues error', e);
          })
          .finally(() => {
            setIsLoadedInitialValues(true);
            setIsLoaded(true);
          });
      }
    } else if (!props?.props?.queries) {
      const parsedInitialValues = merge(
        parseTemplate(props?.props?.initialValues, {
          configs: props?.context?.store?.configs,
          ...variables,
        }),
        props?.context?.store?.[variables?.formName],
      );
      setInitialValues(parsedInitialValues);
      if (props.context?.setStore)
        props.context?.setStore(props.name, parsedInitialValues);
      setIsLoaded(true);
    }
  }, [
    queries,
    variables,
    props?.context?.store?.configs,
    props.context?.refreshHandlers?.[props.props.refreshHandler],
    isLoadedInitialValues,
  ]);

  const [suppressChangeHandler, setSuppressChangeHandler] = useState(false);

  const onFormChange = useCallback(
    (e, formikProps) => {
      setSuppressChangeHandler(false);

      if (props.context?.setStore) {
        props.context?.setStore(props.name, {
          ...props?.context?.store?.[props.name],
          ...formikProps.values,
        });
      }

      if (!suppressChangeHandler) {
        if (props?.props?.onChange && props?.context?.action) {
          props.context.action(
            props.props.onChange,
            {
              ...variables,
              ...props?.context?.store?.[props.name],
              ...formikProps.values,
            },
            {
              sender: props.name,
              actions: props.actions,
              formikContext: formikProps,
            },
          );
        }
        setSuppressChangeHandler(true);
      }
    },
    [props, suppressChangeHandler],
  );

  useEffect(() => {
    if (props?.props?.validationSchema) {
      const schema = validationSchemaToYup(props?.props?.validationSchema);
      setValidationSchema(schema);
    }
  }, [props]);

  const onSubmit = useCallback(
    (formikValues: FormikProps<FormikValues>, formActions: any) => {
      if (props?.props?.onSubmit && props?.context?.action) {
        props.context.action(
          props.props.onSubmit,
          {
            ...variables,
            ...props?.context?.store?.[props.name as string],
            ...formikValues,
          },
          {
            sender: props.name,
            actions: props.actions,
            formikContext: { values: formikValues, ...formActions },
          },
        );
      }
    },
    [props],
  );

  if (!isLoaded) {
    return <LoadingIndicator message={'Loading...'} />;
  }

  return (
    <Formik
      {...props.props?.formikProps}
      initialValues={initialValues}
      enableReinitialize={true}
      className={props.name}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        useEffect(() => {
          onFormChange(null, formikProps);
        }, [formikProps.values]);

        return (
          <Form>
            {props.children?.map((child: ComponentProps, i) => {
              const childWithPrefix = {
                ...child,
                props: {
                  prefix: props.props?.prefix,
                  ...child.props,
                },
                context: props.context,
                variables,
              };

              const componentKey = child.name ? `${child.name}_${i}` : i;

              return (
                <ComponentRender key={componentKey} {...childWithPrefix} />
              );
            })}
          </Form>
        );
      }}
    </Formik>
  );
};
