import { Card } from 'react-bootstrap';
import { ComponentRender } from '../component-render';
import { ComponentProps } from '../layout-interfaces';
import { useCallback } from 'react';

export const CardComponent = (props: ComponentProps) => {
  const onClick = useCallback(
    (e) => {
      e.stopPropagation();

      if (props?.props?.onClick && props.context?.action) {
        props.context?.action(
          props.props.onClick,
          {
            ...props.variables,
          },
          {
            sender: props.name,
            actions: props.actions,
          },
        );
      }
    },
    [props],
  );

  return (
    <Card
      {...props.props?.options}
      className={`default-card ${props.props.className} ${props.props?.options?.className}`}
      onClick={onClick}
    >
      {props.children?.map((child: ComponentProps, i) => (
        <ComponentRender
          key={'card-component-' + i}
          {...child}
          variables={props.variables}
          context={props.context}
        />
      ))}
    </Card>
  );
};
