import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import { FiUploadCloud } from 'react-icons/fi';
import { Icon } from './icon-component';
import {
  executeMutation,
  queryGraphQl,
} from '../../../../graphql/graphql.service';

interface AttachmentFieldProps {
  name: string;
  label?: string;
  options: {
    allowMultiple?: boolean;
    allowClear?: boolean;
    displayAs?: 'image' | 'file';
    maxSize?: number;
    allowedExtensions?: string[];
    previewWidth?: number | string;
    previewHeight?: number | string;
  };
}

const defaultOptions = {
  allowMultiple: false,
  allowClear: false,
  displayAs: 'image',
  maxSize: 10 * 1024 * 1024,
  allowedExtensions: ['image/*', 'application/pdf'],
  previewWidth: '100%',
  previewHeight: '150px',
};

export const AttachmentField: React.FC<AttachmentFieldProps & any> = ({
  field,
  form,
  componentProps,
  ...props
}: any) => {
  const [_, meta, helpers] = useField(field.name);
  const { setFieldValue } = useFormikContext();
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const mergedOptions = { ...defaultOptions, ...componentProps.options };
  const [attachments, setAttachments] = useState<any[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>(
    {},
  );
  const [dragActive, setDragActive] = useState(false);
  const [statusMessage, setStatusMessage] = useState<{
    type: 'success' | 'error' | 'info';
    message: string;
  } | null>(null);

  // Load attachments on mount and when field.value changes
  const loadAttachments = useCallback(async () => {
    if (
      !field.value ||
      (Array.isArray(field.value) && field.value.length === 0)
    ) {
      setAttachments([]);
      return;
    }

    const attachmentIds = Array.isArray(field.value)
      ? field.value
      : [field.value];
    const loaded: any[] = [];

    try {
      for (const attachmentId of attachmentIds) {
        if (!attachmentId) continue;
        const response = await queryGraphQl({
          query: `
            query($organizationId: Int!, $attachmentId: Int!) {
              attachment(organizationId: $organizationId, attachmentId: $attachmentId) {
                attachmentId
                fileName
                description
                fileUri
                presignedFileUri: getPresignedUri(expiresInDays: 1, uriType: "file")
              }
            }`,
          variables: {
            attachmentId: Number(attachmentId),
            organizationId: componentProps.variables?.organizationId,
          },
        });

        if (response.attachment) {
          loaded.push(response.attachment);
        }
      }

      setAttachments(loaded);
    } catch (err) {
      showStatus('error', 'Failed to load attachments');
    }
  }, [field.value, componentProps.variables]);

  useEffect(() => {
    void loadAttachments();
  }, [loadAttachments]);

  // Status message handling
  const showStatus = (type: 'success' | 'error' | 'info', message: string) => {
    setStatusMessage({ type, message });
    setTimeout(() => setStatusMessage(null), 3000);
  };

  // File validation
  const validateFile = useCallback(
    (file: File): string | null => {
      if (mergedOptions.maxSize && file.size > mergedOptions.maxSize) {
        return `File size must be less than ${(
          mergedOptions.maxSize /
          1024 /
          1024
        ).toFixed(1)}MB`;
      }

      if (mergedOptions.allowedExtensions?.length) {
        const fileType = file.type;
        const isAllowed = mergedOptions.allowedExtensions.some(
          (ext: string) => {
            if (ext.endsWith('/*')) {
              const baseType = ext.split('/')[0];
              return fileType.startsWith(`${baseType}/`);
            }
            return ext === fileType;
          },
        );

        if (!isAllowed) {
          return `Allowed file types: ${mergedOptions.allowedExtensions.join(
            ', ',
          )}`;
        }
      }

      return null;
    },
    [mergedOptions],
  );

  // File upload handling
  const handleFiles = useCallback(
    async (files: FileList | null) => {
      if (!files?.length) return;

      const fileArray = mergedOptions.allowMultiple
        ? Array.from(files)
        : [files[0]];
      setIsUploading(true);
      const newAttachmentIds: number[] = [];

      try {
        for (const file of fileArray) {
          const error = validateFile(file);
          if (error) {
            showStatus('error', error);
            continue;
          }

          // Update progress state for this file
          const fileId = `${file.name}-${Date.now()}`;
          setUploadProgress((prev) => ({ ...prev, [fileId]: 0 }));

          // Get pre-signed URL
          const presignedUrlResp = await queryGraphQl({
            query: `
              query($organizationId: Int!, $fileName: String!, $contentType: String!) {
                uploadUrl(organizationId: $organizationId, fileName: $fileName, contentType: $contentType) {
                  presignedUrl
                }
              }`,
            variables: {
              organizationId: componentProps.variables?.organizationId,
              fileName: file.name,
              contentType: file.type,
            },
          });

          const url = presignedUrlResp?.uploadUrl?.presignedUrl;
          if (!url) throw new Error('Failed to get upload URL');

          // Upload with progress tracking
          const xhr = new XMLHttpRequest();
          await new Promise<void>((resolve, reject) => {
            xhr.upload.addEventListener('progress', (event) => {
              if (event.lengthComputable) {
                const progress = Math.round((event.loaded / event.total) * 100);
                setUploadProgress((prev) => ({ ...prev, [fileId]: progress }));
              }
            });

            xhr.addEventListener('load', () => resolve());
            xhr.addEventListener('error', () =>
              reject(new Error('Upload failed')),
            );
            xhr.open('PUT', url);
            xhr.setRequestHeader('Content-Type', file.type);
            xhr.send(file);
          });

          // Create attachment record
          const createAttachmentResult = await executeMutation({
            mutation: `
              mutation CreateAttachmentMutation($input: CreateAttachmentInput!) {
                createAttachment(input: $input) {
                  attachmentGqlDto {
                    attachmentId
                    description
                    fileName
                    fileUri
                    presignedFileUri: getPresignedUri(expiresInDays: 1, uriType: "file")
                  }
                }
              }`,
            variables: {
              input: {
                organizationId: componentProps.variables?.organizationId,
                values: {
                  attachmentType: 'OtherDocument',
                  description: file.name,
                  fileName: file.name,
                  parentId: null,
                  parentType: 'Order',
                  bytesArray: null,
                  fileUrl: url,
                },
              },
            },
          });

          const created =
            createAttachmentResult.createAttachment.attachmentGqlDto;
          newAttachmentIds.push(created.attachmentId);

          // Remove progress for completed file
          setUploadProgress((prev) => {
            const { [fileId]: _, ...rest } = prev;
            return rest;
          });
        }

        // Update form value
        if (mergedOptions.allowMultiple) {
          const existingValue = Array.isArray(field.value) ? field.value : [];
          setFieldValue(field.name, [...existingValue, ...newAttachmentIds]);
        } else if (newAttachmentIds.length > 0) {
          setFieldValue(field.name, newAttachmentIds[0]);
        }

        showStatus('success', 'Files uploaded successfully');
      } catch (error: any) {
        showStatus('error', error.message || 'Upload failed');
      } finally {
        setIsUploading(false);
        setUploadProgress({});
      }
    },
    [
      mergedOptions,
      componentProps.variables,
      field.value,
      field.name,
      setFieldValue,
      validateFile,
    ],
  );

  // Drag and drop handlers
  const handleDrag = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  }, []);

  const handleDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      const { files } = e.dataTransfer;
      if (files?.length) {
        void handleFiles(files);
      }
    },
    [handleFiles],
  );

  // File input change handler
  const handleFileSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      void handleFiles(event.target.files);
      // Reset input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    },
    [handleFiles],
  );

  // Attachment management
  const handleClearAll = useCallback(() => {
    setFieldValue(field.name, mergedOptions.allowMultiple ? [] : null);
    setAttachments([]);
    helpers.setError(undefined);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, [field.name, setFieldValue, mergedOptions.allowMultiple, helpers]);

  const handleRemoveAttachment = useCallback(
    (attachmentId: number) => {
      if (!mergedOptions.allowMultiple) {
        handleClearAll();
      } else {
        const filteredIds = (field.value as number[]).filter(
          (id) => id !== attachmentId,
        );
        setFieldValue(field.name, filteredIds);
        setAttachments((prev) =>
          prev.filter((a) => a.attachmentId !== attachmentId),
        );
      }
    },
    [field.value, mergedOptions.allowMultiple, handleClearAll, setFieldValue],
  );

  const openAttachment = useCallback((attachment: any) => {
    if (attachment?.presignedFileUri) {
      window.open(attachment.presignedFileUri, '_blank');
    }
  }, []);

  const downloadAttachment = useCallback(async (attachment: any) => {
    if (!attachment?.presignedFileUri) return;

    try {
      const response = await fetch(attachment.presignedFileUri);
      if (!response.ok) throw new Error('Download failed');

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = attachment.fileName || 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      showStatus('error', 'Failed to download file');
    }
  }, []);

  const isImageType = (fileName: string) => {
    const lowerName = fileName.toLowerCase();
    return (
      lowerName.endsWith('.png') ||
      lowerName.endsWith('.jpg') ||
      lowerName.endsWith('.jpeg') ||
      lowerName.endsWith('.gif')
    );
  };

  // Generate help text for allowed files
  const getHelpText = () => {
    const sizeMB = (mergedOptions.maxSize / 1024 / 1024).toFixed(1);
    const types = mergedOptions.allowedExtensions
      ?.map((ext: string) => ext.replace('*', 'all'))
      .join(', ');
    return `Upload ${
      mergedOptions.allowMultiple ? 'files' : 'a file'
    } (max ${sizeMB}MB, ${types})`;
  };

  return (
    <div data-testid="attachment-field">
      {/* Status message */}
      {/* {statusMessage && (
        <div
          className={`alert ${
            statusMessage.type === 'error'
              ? 'alert-danger'
              : statusMessage.type === 'success'
              ? 'alert-success'
              : 'alert-info'
          } fade show`}
          role="alert"
        >
          {statusMessage.message}
        </div>
      )} */}

      {/* Upload area */}
      <div
        ref={dropZoneRef}
        style={{
          border: '2px dashed #ccc',
          borderRadius: '4px',
          padding: '1rem',
          textAlign: 'center',
          cursor: isUploading ? 'default' : 'pointer',
          backgroundColor: dragActive ? '#f8f9fa' : '#fff',
        }}
        className="mb-2"
        onClick={() => {
          if (!isUploading) {
            fileInputRef.current?.click();
          }
        }}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDrop={handleDrop}
        role="button"
        tabIndex={0}
        aria-label="Upload area"
      >
        <input
          ref={fileInputRef}
          type="file"
          multiple={mergedOptions.allowMultiple}
          accept={mergedOptions.allowedExtensions?.join(',')}
          onChange={handleFileSelect}
          style={{ display: 'none' }}
          data-testid="attachment-input"
        />

        {isUploading ? (
          <div className="d-flex flex-column align-items-center">
            <div className="spinner-border" role="status" />
            <p className="mt-2 mb-0">Uploading...</p>
            {Object.values(uploadProgress).map((progress, index) => (
              <small key={index} className="text-muted">
                File {index + 1}: {progress}%
              </small>
            ))}
          </div>
        ) : (
          <>
            {attachments.length === 0 ? (
              <div className="d-flex flex-column align-items-center">
                <FiUploadCloud size={32} className="text-primary" />
                <p className="mb-1">Drop files here or click to upload</p>
                <small className="text-muted">{getHelpText()}</small>
              </div>
            ) : (
              <div className="d-flex flex-column">
                {attachments.map((attachment) => (
                  <div
                    key={attachment.attachmentId}
                    className="d-flex align-items-center p-1 mb-2"
                    style={{
                      borderRadius: '4px',
                      backgroundColor: '#f8f9fa',
                    }}
                  >
                    {/* Preview/Icon */}
                    {mergedOptions.displayAs === 'image' &&
                    isImageType(attachment.fileName) ? (
                      <div
                        style={{
                          width: '48px',
                          height: '48px',
                          overflow: 'hidden',
                          flexShrink: 0,
                          borderRadius: '4px',
                        }}
                      >
                        <img
                          src={attachment.presignedFileUri}
                          alt={attachment.fileName}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            cursor: 'pointer',
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            openAttachment(attachment);
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: '48px',
                          height: '48px',
                          flexShrink: 0,
                          borderRadius: '4px',
                        }}
                        className="d-flex align-items-center justify-content-center bg-light"
                      >
                        <Icon icon="tabler-file" size={24} />
                      </div>
                    )}

                    {/* File info */}
                    <div className="flex-grow-1 ml-2">
                      <div
                        className="mb-0 font-weight-bold ml-2 "
                        style={{ textAlign: 'left' }}
                      >
                        {attachment.fileName}
                        {attachment.description && (
                          <>
                            <br />
                            <small
                              className="mb-0 text-truncate text-muted"
                              style={{ textAlign: 'left' }}
                            >
                              {attachment.description}
                            </small>
                          </>
                        )}
                      </div>
                    </div>

                    {/* Actions */}
                    <div className="d-flex align-items-center">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary mr-1"
                        onClick={(e) => {
                          e.stopPropagation();
                          downloadAttachment(attachment);
                        }}
                        aria-label="Download file"
                      >
                        <Icon icon="download" size={18} />
                      </button>

                      {!mergedOptions.allowClear && (
                        <button
                          type="button"
                          className="btn btn-sm  "
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveAttachment(attachment.attachmentId);
                          }}
                          aria-label="Remove file"
                        >
                          X
                        </button>
                      )}
                    </div>
                  </div>
                ))}

                <small className="text-muted text-center">
                  {mergedOptions.allowMultiple
                    ? 'Drop more files or click to upload'
                    : 'Drop a new file or click to replace'}
                </small>
              </div>
            )}
          </>
        )}
      </div>

      {/* Clear all button for multiple files */}
      {mergedOptions.allowClear &&
        mergedOptions.allowMultiple &&
        attachments.length > 1 && (
          <div className="text-center mb-2">
            <button
              type="button"
              className="btn btn-link text-danger p-0"
              onClick={(e) => {
                e.stopPropagation();
                handleClearAll();
              }}
            >
              Clear All
            </button>
          </div>
        )}

      {/* Validation error */}
      {meta?.error && (
        <div className="text-danger" style={{ fontSize: '0.875rem' }}>
          {meta?.error}
        </div>
      )}
    </div>
  );
};
