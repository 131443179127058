import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useStore } from 'effector-react';
import { authStore } from '../auth/auth.store';
import { organizationsStore } from '../organization/organization.store';
import { LOGIN_PATH } from '../auth/auth.router';
import { NOT_FOUND_PATH } from '../notFound/not-found.route';
import { ACCESS_DENIED_PATH } from '../accessDenied/access-denied.route';

export type RootRouteProps = {
  path: string;
};

export const RootRoute = ({ ...rest }: RootRouteProps) => {
  const { user } = useStore(authStore);
  let { organizations, currentOrganization } = useStore(organizationsStore);
  currentOrganization = currentOrganization || organizations[0];

  const location = useLocation();
  const isWrongTrackingReferal = !new RegExp(
    /(^.*\/org{1}\/\d+\/tracking{1}\?orderSelector{1}\=.+$)|(^\/{1}$)/i,
  ).test(`${location.pathname}${location.search}`);

  if (isWrongTrackingReferal) {
    return <Navigate to={NOT_FOUND_PATH} />;
  } else if (user) {
    return organizations.length ? (
      <Navigate
        to={`/org/${currentOrganization.organizationId}/v2/dashboard`}
        replace={true}
      />
    ) : (
      <Navigate to={ACCESS_DENIED_PATH} />
    );
  } else {
    return <Navigate to={LOGIN_PATH} />;
  }
};
