import React, { useState, useEffect, useRef } from 'react';

// 1. Import FontAwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// 2. Import the icons you need
import {
  faSpinner,
  faPaperPlane,
  faUpload,
  faPlus,
  faEdit,
  faCopy,
  faLink,
} from '@fortawesome/free-solid-svg-icons';

/**
 * AIChat Component
 */
const AIChat = ({
  currentContext = null,
  quickQuestions = [],
  onSendMessage,
  onFileUpload,
  onCreateNewThread,
  onLoadThread,
  initialThread = null,
}) => {
  // State
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [currentThread, setCurrentThread] = useState(null);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [messageStates, setMessageStates] = useState({}); // e.g., showSources

  const messagesEndRef = useRef(null);

  // Load initial thread
  useEffect(() => {
    if (initialThread) {
      setCurrentThread(initialThread);
      setMessages(initialThread.messages || []);
    }
  }, [initialThread]);

  // Scroll to bottom on messages change
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(scrollToBottom, [messages]);

  // Handle sending a message
  const handleSendMessage = async () => {
    if (!inputMessage.trim()) return;

    const newMessage = {
      id: Date.now(),
      content: inputMessage,
      type: 'user',
      timestamp: new Date().toISOString(),
    };

    setMessages((prev) => [...prev, newMessage]);
    setInputMessage('');
    setIsStreaming(true);

    try {
      await onSendMessage(newMessage, currentThread?.id);
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsStreaming(false);
    }
  };

  // File upload
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const uploadResult = await onFileUpload(file, currentThread?.id);
      const fileMessage = {
        id: Date.now(),
        content: `Uploaded file: ${file.name}`,
        type: 'file',
        fileData: uploadResult,
        timestamp: new Date().toISOString(),
      };
      setMessages((prev) => [...prev, fileMessage]);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  // Copy to clipboard
  const handleCopyToClipboard = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
      // Optionally display a success message
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  // Regenerate response
  const handleRegenerateResponse = async (messageId) => {
    const messageIndex = messages.findIndex((m) => m.id === messageId);
    const userMessage = messages[messageIndex - 1];
    if (!userMessage) return;

    try {
      setIsStreaming(true);
      await onSendMessage(
        userMessage,
        currentThread?.id,
        true /* regenerate */,
      );
    } catch (error) {
      console.error('Error regenerating response:', error);
    } finally {
      setIsStreaming(false);
    }
  };

  // Toggle sources
  const toggleSourcesDisplay = (messageId) => {
    setMessageStates((prev) => ({
      ...prev,
      [messageId]: {
        ...prev[messageId],
        showSources: !prev[messageId]?.showSources,
      },
    }));
  };

  // Edit message
  const handleEditMessage = (messageId) => {
    setEditingMessageId(messageId);
    const message = messages.find((m) => m.id === messageId);
    setInputMessage(message.content);
  };

  // Save edit (example only)
  const handleSaveEdit = (messageId) => {
    setMessages((prev) =>
      prev.map((msg) =>
        msg.id === messageId ? { ...msg, content: inputMessage } : msg,
      ),
    );
    setEditingMessageId(null);
    setInputMessage('');
  };

  // Create a new thread from edited message
  const handleCreateNewThreadFromEdit = async () => {
    const messageIndex = messages.findIndex((m) => m.id === editingMessageId);
    const newThreadMessages = messages.slice(0, messageIndex + 1);

    try {
      await onCreateNewThread({
        context: currentContext,
        messages: newThreadMessages,
        parentThreadId: currentThread?.id,
      });
    } catch (error) {
      console.error('Error creating new thread:', error);
    }
  };

  return (
    <div
      className="card w-100"
      style={{ maxWidth: '700px', height: '100vh', margin: '0 auto' }}
    >
      {/* Card Header */}
      <div className="card-header d-flex justify-content-between align-items-center">
        <div>
          <h5 className="mb-0">{currentThread?.topic || 'New Chat'}</h5>
          {currentContext?.type && currentContext?.id && (
            <small className="text-muted">
              {currentContext.type}: {currentContext.id}
            </small>
          )}
        </div>
        <button
          type="button"
          className="btn btn-outline-secondary btn-sm d-flex align-items-center"
          onClick={() => onCreateNewThread({ context: currentContext })}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-1" />
          New Chat
        </button>
      </div>

      {/* Card Body */}
      <div className="card-body d-flex flex-column p-0">
        {/* Messages Scroll Area */}
        <div className="flex-grow-1 overflow-auto p-3">
          {messages.map((message) => {
            const isUser = message.type === 'user';
            return (
              <div
                key={message.id}
                className={`mb-4 ${
                  isUser ? 'ml-auto text-right' : 'mr-auto text-left'
                }`}
                style={{ maxWidth: '80%' }}
              >
                {/* Editable Message */}
                {editingMessageId === message.id ? (
                  <div className="border border-primary rounded p-2 bg-white">
                    <textarea
                      className="form-control"
                      value={inputMessage}
                      onChange={(e) => setInputMessage(e.target.value)}
                      rows={3}
                      autoFocus
                    />
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <button
                        type="button"
                        className="btn btn-link btn-sm p-0"
                        onClick={handleCreateNewThreadFromEdit}
                      >
                        <FontAwesomeIcon icon={faCopy} className="mr-1" />
                        Continue in New Thread
                      </button>
                      <div>
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm mr-2"
                          onClick={() => {
                            setEditingMessageId(null);
                            setInputMessage('');
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={() => handleSaveEdit(message.id)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  // Regular Message
                  <div
                    className={`p-3 rounded ${
                      isUser ? 'bg-primary text-white' : 'bg-light'
                    }`}
                  >
                    {message.content}
                  </div>
                )}

                {/* Actions Row */}
                <div className="d-flex align-items-center mt-1">
                  {isUser ? (
                    <>
                      {/* User info & Edit */}
                      <div className="d-flex align-items-center mr-2">
                        <img
                          src={message.user?.avatar || '/api/placeholder/32/32'}
                          alt={message.user?.name || 'User'}
                          className="rounded-circle"
                          style={{
                            width: '20px',
                            height: '20px',
                            marginRight: '6px',
                          }}
                        />
                        <span className="text-muted small">
                          {message.user?.name || 'User'}
                        </span>
                      </div>
                      {!editingMessageId && (
                        <button
                          type="button"
                          className="btn btn-link btn-sm text-muted p-0"
                          onClick={() => handleEditMessage(message.id)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                          Edit
                        </button>
                      )}
                    </>
                  ) : (
                    <>
                      {/* Copy / Regenerate / Sources (AI) */}
                      <button
                        type="button"
                        className="btn btn-link btn-sm text-muted p-0 mr-2"
                        onClick={() => handleCopyToClipboard(message.content)}
                      >
                        <FontAwesomeIcon icon={faCopy} className="mr-1" />
                        Copy
                      </button>
                      <button
                        type="button"
                        className="btn btn-link btn-sm text-muted p-0 mr-2"
                        onClick={() => handleRegenerateResponse(message.id)}
                      >
                        <FontAwesomeIcon icon={faSpinner} className="mr-1" />
                        Regenerate
                      </button>
                      {message.sources && (
                        <button
                          type="button"
                          className="btn btn-link btn-sm text-muted p-0 mr-2"
                          onClick={() => toggleSourcesDisplay(message.id)}
                        >
                          <FontAwesomeIcon icon={faLink} className="mr-1" />
                          Sources
                        </button>
                      )}
                    </>
                  )}

                  <small className="text-muted ml-auto">
                    {new Date(message.timestamp).toLocaleTimeString()}
                  </small>
                </div>

                {/* Sources */}
                {messageStates[message.id]?.showSources && message.sources && (
                  <div className="mt-2 p-2 bg-light border rounded">
                    <div className="font-weight-bold mb-1 small">Sources:</div>
                    {message.sources.map((source, index) => (
                      <div key={index} className="d-flex mb-1 small">
                        <div className="text-muted mr-1">{index + 1}.</div>
                        <div>{source}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
          {/* End of Messages */}
          <div ref={messagesEndRef} />
        </div>

        {/* Quick Questions */}
        {quickQuestions.length > 0 && (
          <div className="border-top p-3">
            <div className="d-flex flex-row" style={{ overflowX: 'auto' }}>
              {quickQuestions.map((question, i) => (
                <button
                  key={i}
                  type="button"
                  className="btn btn-outline-secondary btn-sm mr-2 flex-shrink-0"
                  onClick={() => setInputMessage(question)}
                >
                  {question}
                </button>
              ))}
            </div>
          </div>
        )}

        {/* Message Input / File Upload */}
        <div className="border-top p-3">
          <div className="d-flex">
            <button
              type="button"
              className="btn btn-outline-secondary mr-2"
              onClick={() => document.getElementById('file-upload').click()}
            >
              <FontAwesomeIcon icon={faUpload} />
            </button>
            <input
              id="file-upload"
              type="file"
              className="d-none"
              onChange={handleFileUpload}
            />
            <input
              type="text"
              className="form-control mr-2"
              placeholder="Type your message..."
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            />
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSendMessage}
              disabled={isStreaming || !inputMessage.trim()}
            >
              {isStreaming ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <FontAwesomeIcon icon={faPaperPlane} />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIChat;
