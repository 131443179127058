import { ComponentProps } from '../layout-interfaces';
import { useContext, useEffect, useState } from 'react';
import { parseTemplate } from '../component-hooks';
import logger from '../../../logger';

export const EmbedComponent = (props: ComponentProps) => {
  const [embedUrl, setEmbedUrl] = useState<string>('');

  useEffect(() => {
    try {
      const url = parseTemplate(props.props?.url, {
        ...props?.variables,
        ...props?.context?.store,
      });
      setEmbedUrl(url || ''); // Fallback to empty string if undefined
    } catch (error) {
      logger.error('Error parsing template:', error);
      setEmbedUrl('');
    }
  }, [props.props?.url, props.variables, props.context?.store]);

  return (
    <div {...(props.props || {})} className={props.props?.className}>
      <iframe
        src={embedUrl}
        style={{
          height: '100%',
          width: '100%',
          border: 'none',
          pointerEvents: 'auto',
        }}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={false}
        data-testid="test-id-embed-component"
      />
    </div>
  );
};
