import { NumericFormat } from 'react-number-format';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLongPress } from '../component-hooks';

export const NumberSelectInputComponent = ({
  componentProps,
  field,
  options,
  disabled,
  className,
  onChange,
}) => {
  // increment/decrement speed
  const updateNumbersFrequency = 100;

  const [value, setValue] = useState(field?.value ?? 0);

  const { min: _min, max: _max, step: _step } = componentProps?.props;

  const min = useMemo(() => _min ?? 0, [_min]);
  const max = useMemo(() => _max ?? Number.MAX_VALUE, [_max]);
  const step = useMemo(() => _step ?? 1, [_step]);

  const handleChange = useCallback((value: number) => {
    if (onChange) {
      onChange(value);
    }
  }, []);

  const isAllowed = useCallback(
    (value: number | undefined): boolean => {
      return value === undefined || (value >= min && value <= max);
    },
    [min, max],
  );

  const handleArrowClick = useCallback(
    (operation: 'add' | 'subtract') => {
      setValue((prevValue) => {
        const newValue =
          operation === 'add' ? prevValue + step : prevValue - step;
        if (isAllowed(newValue)) {
          handleChange(newValue);
          return newValue;
        } else {
          return prevValue;
        }
      });
    },
    [step, isAllowed],
  );

  const incrementIntervalRef = useRef<NodeJS.Timeout>(null);
  const decrementIntervalRef = useRef<NodeJS.Timeout>(null);

  const {
    action: incrementAction,
    handlers: incrementHandlers,
  } = useLongPress();

  const {
    action: decrementAction,
    handlers: decrementHandlers,
  } = useLongPress();

  useEffect(() => {
    switch (incrementAction) {
      case 'click':
        clearInterval(incrementIntervalRef.current);
        handleArrowClick('add');
        break;
      case 'longpress':
        incrementIntervalRef.current = setInterval(
          () => handleArrowClick('add'),
          updateNumbersFrequency,
        );
        break;
      default:
        clearInterval(incrementIntervalRef.current);
    }
  }, [incrementAction]);

  useEffect(() => {
    switch (decrementAction) {
      case 'click':
        clearInterval(decrementIntervalRef.current);
        handleArrowClick('subtract');
        break;
      case 'longpress':
        decrementIntervalRef.current = setInterval(
          () => handleArrowClick('subtract'),
          updateNumbersFrequency,
        );
        break;
      default:
        clearInterval(decrementIntervalRef.current);
    }
  }, [decrementAction]);

  return (
    <div style={{ position: 'relative' }}>
      <NumericFormat
        {...field}
        value={value}
        disabled={disabled}
        allowLeadingZeros={false}
        fixedDecimalScale={false}
        displayType={'input'}
        allowNegative={min < 0}
        allowedDecimalSeparators={['.', ',']}
        decimalScale={options?.decimalScale ?? 2}
        thousandSeparator={''}
        className={`pr-7 ${className}`}
        onChange={(e) => {
          const numericValue = parseFloat(e.target.value) || 0;
          handleChange(numericValue);
          setValue(numericValue);
        }}
        isAllowed={({ floatValue }) => isAllowed(floatValue)}
      />
      <div className="number-select-adornment">
        <div className="d-flex flex-column h-1">
          <span className="d-flex cursor-pointer" {...incrementHandlers}>
            <FaCaretUp
              color={'rgb(204, 204, 204)'}
              className={'number-select-arrow'}
            />
          </span>
          <span className="d-flex cursor-pointer" {...decrementHandlers}>
            <FaCaretDown
              color={'rgb(204, 204, 204)'}
              className={'number-select-arrow'}
            />
          </span>
        </div>
      </div>
    </div>
  );
};
